import styles from "./HistoricalChat.module.css";
import { useTranslation } from "react-i18next";
import { DefaultButton, IButtonStyles, IPanelStyles, IStyle, IconButton, Panel, PanelType, PrimaryButton } from "@fluentui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { HistoricalChatReponse } from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@fluentui/react-components";

interface Props {
    className?: string;
    isHistoricalPanelOpen: boolean;
    setIsHistoricalPanelOpen: Dispatch<SetStateAction<boolean>>;
    conversationId?: string;
    setConversationId: Dispatch<SetStateAction<string>>;
    historicalChats: HistoricalChatReponse[];
    isIframeChat?: boolean;
    disabled?: boolean;
    deleteConversation(id: string): void;
}

export const HistoricalChat = ({
    className,
    disabled,
    isHistoricalPanelOpen,
    setIsHistoricalPanelOpen,
    conversationId,
    setConversationId,
    historicalChats,
    deleteConversation,
    isIframeChat
}: Props) => {
    const { t, i18n } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

    const customButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: "var(--color-primary)",
            color: "var(--color-background)",
            borderRadius: "21px",
            fontFamily: "Roboto, sans-serif",
            fontStyle: "normal",
            fontWeight: 700
        },
        rootHovered: {
            backgroundColor: "var(--color-secondary)",
            color: "var(--color-font)"
        },
        rootPressed: {
            backgroundColor: "var(--color-secondary)",
            color: "var(--color-font)"
        }
    };
    const panelStyles: IPanelStyles = {
        root: {
            marginTop: isIframeChat ? "33px" : "0px"
        },
        overlay: undefined,
        hiddenPanel: undefined,
        main: {
            backgroundColor: "var(--color-background-secondary)",
            color: "var(--color-font)"
        },
        commands: {
            backgroundColor: "var(--color-background-secondary)",
            color: "var(--color-font)"
        },
        contentInner: undefined,
        scrollableContent: undefined,
        navigation: undefined,
        header: undefined,
        headerText: {
            color: "var(--color-font)"
        },
        content: undefined,
        footer: undefined,
        subComponentStyles: {
            closeButton: {
                root: {
                    display: "none"
                }
            }
        },
        footerInner: {
            backgroundColor: "var(--color-background-secondary)",
            color: "var(--color-font)",
            display: "flex",
            justifyContent: "flex-end"
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth < 720);
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    const onClick = (chatId: string) => {
        if (conversationId !== chatId) {
            setConversationId(chatId);
        }
    };

    return (
        <div className={`${styles.container}`}>
            <Panel
                headerText={t("historical.header")}
                isOpen={isHistoricalPanelOpen}
                isBlocking={false}
                onDismiss={() => setIsHistoricalPanelOpen(false)}
                closeButtonAriaLabel={t("common.close")}
                onRenderFooterContent={() => (
                    <DefaultButton styles={customButtonStyles} onClick={() => setIsHistoricalPanelOpen(false)}>
                        {t("common.close")}
                    </DefaultButton>
                )}
                isFooterAtBottom={true}
                hasCloseButton={!isIframeChat}
                styles={panelStyles}
                type={PanelType.customNear}
                customWidth={isMobile ? "100%" : "544px"}
                className={`${styles.panel}`}
            >
                <div className={styles.content}>
                    <div className={styles.historicalChats}>
                        {historicalChats &&
                            historicalChats.map((chat, index) => (
                                <div
                                    key={index}
                                    className={`${styles.historicalChat} ${disabled || conversationId == chat.id ? styles.disabledHistoricalChat : ""}`}
                                    onClick={() => onClick(chat.id)}
                                >
                                    <div className={styles.chatContent}>
                                        <div className={styles.chatTitle}>{chat.title}</div>
                                        <DefaultButton className={styles.deleteButton} onClick={() => deleteConversation(chat.id)} disabled={disabled}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </DefaultButton>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Panel>
        </div>
    );
};
