import { t } from "i18next";
import { exportToExcel } from "../../utils/excel";
import { redirectTo } from "../../utils/redirect";
import { copyToClipboard } from "../../utils/copy";

// create class to create a prototype of kinds of buttons and then create instances of those buttons
export class AnswerButtonPrototype {
    public static Of(type: AnswerButtonType, buttonId: string, show?: boolean, onClick?: (event: HTMLElement) => void): AnswerButton {
        const prototype = this.prototypes.get(type);
        if (prototype) {
            return {
                ...prototype,
                id: buttonId,
                onClick: onClick || prototype.onClick,
                show: show
            };
        }

        throw new Error(`AnswerButton type ${type} not found`);
    }

    private static prototypes: Map<AnswerButtonType, AnswerButton> = this.init();
    public static init() {
        const proto = new Map<AnswerButtonType, AnswerButton>();
        proto.set(
            AnswerButtonType.DOWNLOAD_TABLE,
            this.createButton("Descargar tabla", "Download", event => {
                const id = event?.id;
                if (!id) {
                    return;
                }
                exportToExcel(id);
            })
        );
        proto.set(
            AnswerButtonType.REDIRECT,
            this.createButton("Redirect to", "Link", event => {
                const id = event?.id;
                if (!id) {
                    return;
                }
                redirectTo(id, false);
            })
        );
        proto.set(
            AnswerButtonType.EXTERNAL_REDIRECT,
            this.createButton("Redirect to", "Link", event => {
                const id = event?.id;
                if (!id) {
                    return;
                }
                redirectTo(id, true);
            })
        );
        proto.set(
            AnswerButtonType.REGENERATE_ANSWER,
            this.createButton("Regenerate answer", "Refresh", event => {
                const id = event?.id;
                if (!id) {
                    return;
                }
                const answer = document.getElementById(id);
                if (answer) {
                    answer.style.display = "none";
                }
            })
        );
        proto.set(
            AnswerButtonType.COPY_ANSWER,
            this.createButton("Copy answer", "Copy", event => {
                const id = event?.id;
                if (!id) {
                    return;
                }
                copyToClipboard(id);
            })
        );

        return proto;
    }

    private static createButton(title: string, icon: string, onClick: (event: HTMLElement) => void): AnswerButton {
        return {
            id: undefined,
            title: title,
            icon: icon,
            onClick: onClick
        };
    }
}

export enum AnswerButtonType {
    DOWNLOAD_TABLE = "download_table",
    REDIRECT = "redirect",
    EXTERNAL_REDIRECT = "external_redirect",
    REGENERATE_ANSWER = "regenerate_answer",
    COPY_ANSWER = "copy_answer"
}

export interface AnswerButton {
    id?: string;
    icon?: string;
    title?: string;
    onClick?: (event: HTMLElement) => void;
    show?: boolean;
}
