import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import { useTranslation } from "react-i18next";

interface Props {
    isDarkMode?: boolean;
    tenant?: string;
}

export const AnswerLoading: React.FC<Props> = ({ isDarkMode, tenant }) => {
    const { t, i18n } = useTranslation();

    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon isDarkMode={isDarkMode} tenant={tenant} />
                    </Stack>
                </Stack.Item>
                <Stack.Item grow>
                    <p className={styles.answerText}>
                        {t("chat.answers.loading")}
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
