import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, Navigate, Router, RouterProvider, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";
import { type, msalConfig, loginConfig, useLogin, refreshAuthSetup } from "./authConfig";

import "./index.css";
import "./i18n";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import AuthProvider from "./pages/authprovider/AuthProvider";
import { initializeChatEvent } from "./embed";
import { insideIframe } from "./utils/iframe";
import { initializeDarkMode } from "./utils/dark";
import { LoadingPage } from "./components/Spinner/Spinner";
import { initLogs } from "./utils/logs";
import useTenant, { addTenantToUrl, getTenant, removeTenant } from "./utils/tenant";
import { removeAccessToken, removeRefreshToken } from "./auth/token";

// At the top level, outside your App component.
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// initLogs();
initializeIcons();
initializeChatEvent();
initializeDarkMode(insideIframe ? true : undefined);

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tenant, setTenant] = useTenant();
    const routerRef = useRef<any>(undefined);
    const lastTenant = useRef<string | undefined>(getTenant());

    useEffect(() => {
        if (tenant) {
            refreshTenant();
        } else {
            performLogout();
        }
    }, [tenant]);

    const refreshTenant = async () => {
        setIsLoading(true);
        lastTenant.current = tenant;

        await refreshAuthSetup();
        layout();
        customCss();

        setIsLoading(false);
    };

    const performLogout = () => {
        setIsLoading(true);
        lastTenant.current = undefined;

        removeAccessToken();
        removeRefreshToken();

        setIsLoading(false);
        window.location.href = "/";
    };

    const layout = () => {
        let layout = (
            <div className="container">
                <Layout tenant={tenant} />
            </div>
        );
        if (useLogin()) {
            if (type() === "msal") {
                var msalInstance = new PublicClientApplication(msalConfig());

                // Default to using the first account if no account is active on page load
                if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                    // Account selection logic is app dependent. Adjust as needed for different use cases.
                    msalInstance.setActiveAccount(msalInstance.getActiveAccount());
                }

                // Listen for sign-in event and set active account
                msalInstance.addEventCallback(event => {
                    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                        const account = event.payload as AccountInfo;
                        msalInstance.setActiveAccount(account);
                    }
                });

                layout = <MsalProvider instance={msalInstance}>{layout}</MsalProvider>;
            } else {
                const redirectUri = window.location.origin + loginConfig().auth.redirectUri;
                layout = (
                    <div className="container">
                        <AuthProvider
                            key="oauth-provider"
                            tenant={tenant}
                            authorizeUri={loginConfig().auth.authority + "/connect/authorize"}
                            tokenUri={loginConfig().auth.authority + "/connect/token"}
                            logoutUri={loginConfig().auth.authority + "/Account/Logout"}
                            clientId={loginConfig().auth.clientId}
                            scope={loginConfig().auth.scopes.join(" ")}
                            responseType={"code"}
                            redirectUri={redirectUri}
                        >
                            {layout}
                        </AuthProvider>
                    </div>
                );
            }
        }

        const router = createHashRouter([
            {
                path: "/",
                element: layout,
                children: [
                    {
                        index: true,
                        element: <Navigate to="chat" />
                    },
                    {
                        path: "chat/:conversationId",
                        element: <Chat />
                    },
                    {
                        path: "chat",
                        element: <Chat />
                    },
                    {
                        path: "qa",
                        lazy: () => import("./pages/ask/Ask")
                    },
                    {
                        path: "*",
                        lazy: () => import("./pages/NoPage")
                    }
                ]
            },
            {
                path: "/logout",
                element: layout
            }
        ]);
        routerRef.current = router;
    };

    // create a new style tag with the custom css
    const customCss = () => {
        const linkStyleSheetCreator = (cssLink: string, withTenant = true) => {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.type = "text/css";
            link.href = addTenantToUrl(cssLink, tenant);
            document.head.appendChild(link);
        };
        // add custom.css and layout.css
        linkStyleSheetCreator("/assets/css/custom.css");
        linkStyleSheetCreator("/assets/css/layout.css");
    };

    if (isLoading) {
        return <LoadingPage />;
    }

    return <React.StrictMode>{routerRef.current?.routes?.length > 0 && <RouterProvider router={routerRef.current} />}</React.StrictMode>;
};

root.render(<App />);
