export function toParams(query: string) {
    // TODO: parsedQuery is a workaround to preserve the old behavior of toParams
    // but we should analyze if we can/should remove it
    const parsedQuery = query.replace(/^\??\//, "");
    return Object.fromEntries(new URLSearchParams(parsedQuery));
}

export function toQuery(params: { [key: string]: string | number | boolean | null | undefined }, delimiter: string = "&"): string {
    const keys = Object.keys(params);
    return keys.reduce((str, key, index) => {
        const value = params[key];
        if (value === undefined || value === null) return str;
        let query = `${str}${key}=${encodeURIComponent(value.toString())}`;
        if (index < keys.length - 1) {
            query += delimiter;
        }
        return query;
    }, "");
}
