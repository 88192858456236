import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const DEFAULT_EXAMPLES: ExampleModel[] = [
    { text: "¿Me puedes dar detalles de dónde está el envío FR0031C5?", value: "¿Me puedes dar detalles de dónde está el envío FR0031C5?" },
    {
        text: "Crea una tabla con los últimos envíos de courier.",
        value: "Crea una tabla con los últimos envíos de courier. El formato de la tabla es: Código de envío, Agencía, Fecha de recogida, Fecha de entrega, Precio, Tipo de envío, Origen y Destino."
    },
    { text: "Dime los envíos cuyo destino es Barcelona.", value: "Dime los envíos cuyo destino es Barcelona." },
    {
        text: "Me puedes dar las actualizaciones de seguimiento del envío FR003308?",
        value: "Me puedes dar las actualizaciones de seguimiento del envío FR003308?"
    }
];

const GPT4V_EXAMPLES: ExampleModel[] = [
    { text: "¿Me puedes dar detalles de dónde está el envío FR0031C5?", value: "¿Me puedes dar detalles de dónde está el envío FR0031C5?" },
    {
        text: "Crea una tabla con los últimos envíos de courier.",
        value: "Crea una tabla con los últimos envíos de courier. El formato de la tabla es: Código de envío, Agencía, Fecha de recogida, Fecha de entrega, Precio, Tipo de envío, Origen y Destino."
    },
    { text: "Dime los envíos cuyo destino es Barcelona.", value: "Dime los envíos cuyo destino es Barcelona." }
];

interface Props {
    examples?: ExampleModel[];
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ examples, onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(examples && examples.length ? examples : useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li className={styles.exampleLi} key={i}>
                    <Example text={question.text} value={question.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
