import { Stack, PrimaryButton, IconButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import { faCog, faRedo, faRestroom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@fluentui/react-components";

interface Props {
    error: string;
    text?: string;
    isDarkMode?: boolean;
    tenant?: string;
    onRetry: () => void;
}

const CheckIsNullOrEmtyOrUndefined = (value: string | undefined | null): boolean => {
    return value === undefined || value === null || value === "";
};

export const AnswerError = ({ error, text, isDarkMode, tenant, onRetry }: Props) => {
    const buttons = [
        {
            title: "Retry",
            icon: "Refresh",
            onClick: onRetry
        }
    ];

    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            {!CheckIsNullOrEmtyOrUndefined(text) && (
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon isDarkMode={isDarkMode} tenant={tenant} />
                    </Stack>
                </Stack.Item>
            )}
            {CheckIsNullOrEmtyOrUndefined(text) && <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />}
            <Stack.Item grow>
                <p className={styles.answerText}>{CheckIsNullOrEmtyOrUndefined(text) ? error : text}</p>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                    {buttons.map((x, i) => {
                        return (
                            <IconButton
                                key={i}
                                className={styles.button}
                                iconProps={{ iconName: x.icon }}
                                title={x.title}
                                ariaLabel={x.title}
                                onClick={() => onRetry()}
                            />
                        );
                    })}
                </Stack>
            </Stack.Item>
        </Stack>
    );
};
