import { Edit24Regular, Settings24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./HistoricalButton.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const HistoricalButton = ({ className, disabled, onClick }: Props) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Button disabled={disabled} icon={<FontAwesomeIcon icon={faList} />} onClick={onClick}></Button>
        </div>
    );
};
