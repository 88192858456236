import { useEffect, useState, useCallback } from "react";

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

const REFRESH_TIME = 5 * 60 * 1000; // 5 minutes

// Exported helper functions
export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || undefined;
}

export function setAccessToken(token: string | undefined) {
    if (!token) {
        return;
    }
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
}

export function isJwtExpired(token: string | undefined) {
    if (!token) {
        return true;
    }

    const jwt = getJwtPayload(token);
    const exp = jwt.exp * 1000;
    const now = new Date().getTime();

    return now > exp;
}

export function getJwtPayload(token: string | undefined) {
    if (!token) {
        return undefined;
    }

    return JSON.parse(atob(token.split(".")[1]));
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY) || undefined;
}

export function setRefreshToken(token: string | undefined) {
    if (!token) {
        return;
    }
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

export function removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function isLogged(token: string | undefined = getAccessToken()) {
    const jwt = token || getAccessToken();
    return jwt !== undefined && !isJwtExpired(jwt);
}

// Custom hook
export function useAuthToken(onRefresingToken?: () => Promise<void>) {
    const [accessToken, setAccessTokenState] = useState(getAccessToken());
    const [refreshToken, setRefreshTokenState] = useState(getRefreshToken());
    const [isLoggedIn, setIsLoggedIn] = useState(isLogged());

    const updateAccessToken = useCallback((token: string | undefined) => {
        setAccessToken(token);
        setAccessTokenState(token);
        setIsLoggedIn(isLogged(token));
    }, []);

    const clearAccessToken = useCallback(() => {
        removeAccessToken();
        setAccessTokenState(undefined);
        setIsLoggedIn(false);
    }, []);

    const updateRefreshToken = useCallback((token: string | undefined) => {
        setRefreshToken(token);
        setRefreshTokenState(token);
        setIsLoggedIn(isLogged());
    }, []);

    const clearRefreshToken = useCallback(() => {
        removeRefreshToken();
        setRefreshTokenState(undefined);
        setIsLoggedIn(false);
    }, []);

    // Effect to listen to changes in localStorage
    useEffect(() => {
        const handleStorageChange = () => {
            setAccessTokenState(getAccessToken());
            setRefreshTokenState(getRefreshToken());
            setIsLoggedIn(isLogged());
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (onRefresingToken) {
        // Refresh token when token will expire in 5 minutes
        useEffect(() => {
            if (accessToken && !isJwtExpired(accessToken)) {
                const jwt = getJwtPayload(accessToken);
                const exp = jwt.exp * 1000;
                const now = new Date().getTime();

                const timeToRefresh = exp - now - REFRESH_TIME;
                if (timeToRefresh <= 0) {
                    return;
                }
                const timeout = setTimeout(() => {
                    onRefresingToken && onRefresingToken();
                }, timeToRefresh);

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [accessToken, updateAccessToken, updateRefreshToken]);
    }

    return [accessToken, updateAccessToken, clearAccessToken, refreshToken, updateRefreshToken, clearRefreshToken, isLoggedIn, refreshToken] as const;
}
