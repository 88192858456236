import i18n from "./i18n";
import { retry } from "./utils/timeout";
import { insideIframe } from "./utils/iframe";

export function initializeChatEvent() {
    window.addEventListener("message", processNewMessage, false);
}

const processingTypes: any = {};
const pendingMessages: any[] = [];
let loaded = false;
async function processNewMessage(message: MessageEvent) {
    if (!message.data || !message.data.type) {
        return;
    }

    // Check if this type of message is already being processed
    if (processingTypes[message.data.type]) {
        console.log(`Message of type ${message.data.type} is already being processed.`);
        return;
    }

    // check if page is loaded, if not, wait for it to load
    const excludeTypes = [EventTypes.EVENT_IFRAME_LOADED, EventTypes.EVENT_PAGE];
    if (!loaded && !excludeTypes.includes(message.data.type)) {
        pendingMessages.push(message);
        console.log("chat - page not loaded, waiting for it to load", pendingMessages);
        return;
    }

    // Mark this type of message as being processed
    processingTypes[message.data.type] = true;
    try {
        switch (message.data.type) {
            case EventTypes.EVENT_PAGE:
                console.log("chat - page location: " + message.data.type);
                //ignore
                break;

            case EventTypes.EVENT_IFRAME_LOADED:
                console.log("chat - iframe loaded: " + message.data.type);
                loaded = true;
                pendingMessages.forEach(processNewMessage);
                break;

            case EventTypes.EVENT_LANGUAGE_CHANGED:
                console.log("chat - language changed: " + message.data.type);
                i18n.changeLanguage(message.data.data);
                break;

            case EventTypes.EVENT_LOGGING:
            case EventTypes.EVENT_LOGGED:
                console.log("chat - login events: " + message.data.type);
                await retry(
                    async () => {
                        const chatWrapper = document.getElementById("chat-wrapper");
                        if (chatWrapper) {
                            return;
                        }

                        const loginButton = document.getElementById("login-button");
                        if (loginButton) {
                            loginButton.click();
                        } else {
                            throw new Error("Login button not found");
                        }
                    },
                    3,
                    200
                );
                break;
            case EventTypes.EVENT_LOGGING_OUT:
                console.log("chat - logout events: " + message.data.type);
                await retry(
                    async () => {
                        const loginButton = document.getElementById("login-button");
                        if (loginButton) {
                            emitEvent(EventTypes.EVENT_LOGGED_OUT, message.origin);
                            return;
                        }

                        const logoutButton = document.getElementById("logout-button");
                        if (logoutButton) {
                            logoutButton.click();
                            emitEvent(EventTypes.EVENT_LOGGED_OUT, message.origin);
                        } else {
                            throw new Error("Logout button not found");
                        }
                    },
                    3,
                    200
                );
                break;
            default:
                console.log("chat - unknown events: " + message.data?.type);
                break;
        }
    } catch (error) {
        console.error("Error processing message:", error);
    } finally {
        // Mark this type of message as no longer being processed
        delete processingTypes[message.data.type];
    }
}

export function emitEvent(eventType: EventTypes, origin?: string, data?: any) {
    console.log("chat - emitting event: " + eventType);
    const w = window.parent || window;
    w.postMessage(
        {
            type: eventType,
            data
        },
        origin || "*"
    );
}

export enum EventTypes {
    EVENT_PAGE = "page-loaded",

    EVENT_IFRAME_CREATED = "iframe-created",
    EVENT_IFRAME_LOADING = "iframe-loading",
    EVENT_IFRAME_LOADED = "iframe-loaded",

    // Login events
    EVENT_LOGIN = "login",
    EVENT_LOGGING = "logging",
    EVENT_LOGGED = "logged",

    // Logout events
    EVENT_LOGOUT = "logout",
    EVENT_LOGGING_OUT = "logging-out",
    EVENT_LOGGED_OUT = "logged-out",

    // Color mode events
    EVENT_COLOR_MODE = "color-mode",

    // Freightol events
    EVENT_LANGUAGE_CHANGED = "language-changed",
    EVENT_REDIRECT = "redirect"
}
