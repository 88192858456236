import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const exportToExcel = (tableId: string, filename?: string) => {
    const table = document.getElementById(tableId);
    const workbook = XLSX.utils.table_to_book(table);
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    function s2ab(s: string) {
        const buffer = new ArrayBuffer(s.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buffer;
    }

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), filename || `export_${tableId}.xlsx`);
};
