import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Layout.module.css";

import { useLogin, type } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import { useEffect, useState } from "react";
import { EventTypes, emitEvent } from "../../embed";
import { LogoHeader } from "../logo/Logo";
import { insideIframe } from "../../utils/iframe";
import { useColorScheme } from "../../utils/dark";
import { LoadingPage } from "../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";

interface LayoutProps {
    tenant?: string;
}

const Layout = ({ tenant }: LayoutProps) => {
    const { t, i18n } = useTranslation();
    const [isIframeChat, setIsIframeChat] = useState(insideIframe);
    const [showHeader, setShowHeader] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isDarkMode, toggleColorScheme] = useColorScheme();

    // list of children components
    const [navChildren, setNavChildren] = useState<JSX.Element[]>([]);

    useEffect(() => {
        emitEvent(EventTypes.EVENT_IFRAME_LOADED);
    }, []);

    let scrollTimeout: number | undefined | null | any = null;
    useEffect(() => {
        const handleScroll = () => {
            // Clear the timeout on every scroll event
            if (scrollTimeout !== null) {
                clearTimeout(scrollTimeout);
            }

            // Set a timeout to delay the execution of the scroll handler
            scrollTimeout = setTimeout(() => {
                const newValue = window.scrollY < 30;
                setShowHeader(newValue);
            }, 20);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (scrollTimeout !== null) {
                clearTimeout(scrollTimeout);
            }
        };
    }, []);
    useEffect(() => {
        document.title = t("main.title");
    }, [i18n.language]);

    const toggleLoading = (value: boolean) => {
        setIsLoading(value);
    };

    const outletContext: OutletContext = [tenant, isIframeChat, isDarkMode, setNavChildren, toggleColorScheme, toggleLoading];
    return (
        <div className={styles.layout}>
            {!isIframeChat && (
                <header id="header" className={`${styles.header} ${!showHeader ? styles.hideHeader : ""}`} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <LogoHeader className={styles.headerLogo} isDarkMode={isDarkMode} tenant={tenant} />
                        <h4 className={styles.headerRightText}>{t("main.title")}</h4>
                        {useLogin() && type() == "msal" && <LoginButton />}
                    </div>
                </header>
            )}
            {navChildren && navChildren.length > 0 && <nav className={styles.nav}>{navChildren}</nav>}
            {isLoading && <LoadingPage />}
            {<Outlet context={outletContext} />}
        </div>
    );
};

export default Layout;
export type OutletContext = [string | undefined, boolean, boolean, (children: JSX.Element[]) => void, () => void, (value: boolean) => void];
