import React, { Component } from "react";
import PropTypes from "prop-types";

import LoginOAuth2Window from "./LoginOAuth2Window";
import { toQuery } from "../../../../utils/utils";

class LoginOAuth2 extends Component {
    static propTypes = {
        id: PropTypes.string,
        authorizeUri: PropTypes.string,
        buttonText: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        clientId: PropTypes.string.isRequired,
        onRequest: PropTypes.func,
        onSuccess: PropTypes.func,
        onFailure: PropTypes.func,
        popupHeight: PropTypes.number,
        popupWidth: PropTypes.number,
        redirectUri: PropTypes.string,
        scope: PropTypes.string,
        state: PropTypes.string,
        responseType: PropTypes.string,
        disabled: PropTypes.bool,
        params: PropTypes.object
    };

    static defaultProps = {
        id: "github-oauth2-authorize",
        buttonText: "Sign in",
        redirectUri: "",
        authorizeUri: "",
        state: "",
        scope: "email",
        responseType: "code",
        popupHeight: 650,
        popupWidth: 500,
        buttonRef: null,
        onRequest: () => {},
        onSuccess: () => {},
        onFailure: () => {},
        params: {}
    };

    click = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }

        const { id, authorizeUri, clientId, scope, redirectUri, responseType, state, popupHeight, popupWidth, params } = this.props;
        const search = toQuery({
            client_id: clientId,
            scope,
            redirect_uri: redirectUri,
            response_type: responseType,
            state,
            ...params
        });

        // To fix issues with window.screen in multi-monitor setups, the easier option is to
        // center the pop-up over the parent window.
        // Be aware that this will not work if the parent window is on iframes, so we need to use try/catch
        let top, left;

        try {
            top = window.top.outerHeight / 2 + window.top.screenY - popupHeight / 2;
            left = window.top.outerWidth / 2 + window.top.screenX - popupWidth / 2;
        } catch (e) {
            // Fallback if window.top properties are not accessible
            top = window.innerHeight / 2 + window.screenY - popupHeight / 2;
            left = window.innerWidth / 2 + window.screenX - popupWidth / 2;
        }

        const url = `${authorizeUri}?${search}`;
        const popup = (this.popup = LoginOAuth2Window.open(id, url, {
            height: popupHeight,
            width: popupWidth,
            top,
            left
        }));

        this.onRequest();
        popup.then(
            data => this.onSuccess(data),
            error => this.onFailure(error)
        );
    };

    onRequest = () => {
        this.props.onRequest();
    };

    onSuccess = data => {
        const { responseType } = this.props;
        if (data.error) {
            return this.onFailure(new Error(`'${data.error}': ${data.error_description}`));
        }

        if (!data[responseType]) {
            return this.onFailure(new Error(`'${responseType}' not found: ${JSON.stringify(data)}`));
        }

        this.props.onSuccess(data);
    };

    onFailure = error => {
        this.props.onFailure(error);
    };

    render() {
        const { className, buttonText, children, disabled, buttonRef } = this.props;
        const attrs = {
            onClick: this.click,
            className: className || "",
            disabled: disabled || false,
            ref: buttonRef
        };
        return (
            <button id="login-button" {...attrs}>
                {children || buttonText}
            </button>
        );
    }
}

export default LoginOAuth2;
