import { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import { AnswerButton, AnswerButtonPrototype, AnswerButtonType } from "./AnswerButton";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import React from "react";
import styles from "./Answer.module.css";
import DOMPurify from "dompurify";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    buttons: AnswerButton[];
};

export function parseAnswerToHtml(
    answer: string,
    isStreaming: boolean,
    isIframeChat: boolean,
    answerId: string,
    lastAnswer: boolean,
    onCitationClicked: (citationFilePath: string) => void,
    onRegenerateAnswerClicked: (answerId: string) => void
): HtmlParsedAnswer {
    // Initial state
    let result: HtmlParsedAnswer = {
        answerHtml: preventNotYetItems(answer.trim(), isStreaming),
        citations: [],
        buttons: []
    };

    // Process citations
    result = processCitations(result, isStreaming, onCitationClicked);

    // Parse Markdown to HTML
    result = processMarkdown(result, isStreaming, isIframeChat);

    // Process buttons
    result = processButtons(result, isStreaming, answerId, lastAnswer, onRegenerateAnswerClicked);

    // Return the final result
    return result;
}

function processMarkdown(result: HtmlParsedAnswer, isStreaming: boolean, isIframeChat: boolean): HtmlParsedAnswer {
    let { answerHtml: parsedAnswer, citations, buttons } = result;

    const combineClassNames = (existingClasses: string, newClasses: string) => {
        const existing = existingClasses || "";
        const creating = newClasses || "";
        const result = `${existing} ${creating}`.trim();
        return result ? result : undefined;
    };

    // Define custom renderers
    const renderers: any = {
        // Custom link renderer
        a: ({ href, title, children, className, ...props }: any) => {
            const isValidHref = href?.startsWith("http");
            let properties: any = {
                ...props,
                id: generateId(),
                target: "_blank",
                title: title || href
            };

            // Function to call the button action by id
            const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
                e.preventDefault();

                if (isValidHref && isIframeChat) {
                    document.getElementById(id)?.click();
                } else {
                    window.open(href, properties.target);
                }
            };

            if (isValidHref) {
                const type = isIframeChat ? AnswerButtonType.REDIRECT : AnswerButtonType.EXTERNAL_REDIRECT;
                buttons.push(AnswerButtonPrototype.Of(type, properties.id));
            }

            return (
                <a href={href} className={className} onClick={e => handleClick(e, properties.id)} {...properties}>
                    <b>{children}</b>
                </a>
            );
        },

        // Custom table renderer
        table: ({ children, className, ...props }: any) => {
            const tableId = generateId();
            buttons.push(AnswerButtonPrototype.Of(AnswerButtonType.DOWNLOAD_TABLE, tableId));
            return (
                <table id={tableId} data-filename={tableId} className={combineClassNames(className, styles.customTable)} {...props}>
                    {children}
                </table>
            );
        },

        // Custom list renderer
        ol: ({ children, className, ...props }: any) => {
            return (
                <ol className={combineClassNames(className, styles.customOl)} {...props}>
                    {children}
                </ol>
            );
        },
        ul: ({ children, className, ...props }: any) => {
            return (
                <ul className={combineClassNames(className, styles.customUl)} {...props}>
                    {children}
                </ul>
            );
        },

        // Custom list item renderer
        li: ({ children, className, ...props }: any) => {
            return (
                <li className={combineClassNames(className, styles.customLi)} {...props}>
                    {children}
                </li>
            );
        },

        // Custom code block renderer
        code: ({ node, inline, className, children, ...props }: any) => {
            // Return an empty string to prevent displaying code blocks
            return <code className="blurred-code-block">Code Block</code>;
        },
        codespan: ({ children, ...props }: any) => {
            return <pre className="blurred-inline-code">Inline Code</pre>;
        },

        // Custom image renderer
        image: ({ src, alt }: any) => {
            return <img src={src} alt={alt} />;
        }
    };

    // Parse the answer
    const res = renderToStaticMarkup(
        <ReactMarkdown remarkPlugins={[remarkGfm]} skipHtml={false} components={renderers} rehypePlugins={[rehypeRaw]}>
            {parsedAnswer}
        </ReactMarkdown>
    );

    return {
        answerHtml: res,
        citations: citations,
        buttons: buttons
    };
}

function processCitations(result: HtmlParsedAnswer, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    let { answerHtml: parsedAnswer, citations, buttons } = result;

    const regex = /\[([^\]]+)\](?!\()/g;
    const parts = parsedAnswer.split(regex);
    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part);

            return renderToStaticMarkup(
                <a className="supContainer" href="#" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations: [...new Set(citations)],
        buttons: buttons
    };
}

function generateId(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function processButtons(
    result: HtmlParsedAnswer,
    isStreaming: boolean,
    answerId: string,
    lastAnswer: boolean,
    onRegenerateAnswerClicked: (answerId: string) => void
): HtmlParsedAnswer {
    let { answerHtml: parsedAnswer, citations, buttons } = result;

    // Add a button to regenerate the answer
    const showRegenerateButton = !isStreaming || lastAnswer;
    buttons.push(AnswerButtonPrototype.Of(AnswerButtonType.REGENERATE_ANSWER, answerId, !isStreaming && lastAnswer, () => onRegenerateAnswerClicked(answerId)));

    // Add a button to copy the answer
    const showCopyButton = !(isStreaming && lastAnswer);
    buttons.push(AnswerButtonPrototype.Of(AnswerButtonType.COPY_ANSWER, answerId, showCopyButton));

    return {
        answerHtml: parsedAnswer,
        citations: citations,
        buttons: buttons
    };
}

// prevent functions
function preventNotYetItems(parsedAnswer: string, isStreaming: boolean): string {
    // Prevent showing not yet citations
    parsedAnswer = preventShowNotYetCitation(parsedAnswer, isStreaming);

    // Prevent showing not yet tables
    parsedAnswer = preventShowNotYetTable(parsedAnswer, isStreaming);

    return parsedAnswer;
}

function preventShowNotYetCitation(parsedAnswer: string, isStreaming: boolean): string {
    if (!isStreaming) {
        return parsedAnswer;
    }
    // Omit a citation that is still being typed during streaming
    let lastIndex = parsedAnswer.length;
    for (let i = parsedAnswer.length - 1; i >= 0; i--) {
        if (parsedAnswer[i] === "]") {
            break;
        } else if (parsedAnswer[i] === "[") {
            lastIndex = i;
            break;
        }
    }
    return parsedAnswer.substring(0, lastIndex);
}

function preventShowNotYetTable(parsedAnswer: string, isStreaming: boolean): string {
    if (!isStreaming) {
        return parsedAnswer;
    }

    let lastIndex = parsedAnswer.length;
    // let lines = parsedAnswer.split("\n");

    // let headerDetected = false;
    // let subheaderDetected = false;
    // let firstRowDetected = false;

    // for (let i = lines.length - 1; i >= 0; i--) {
    //     if (lines[i].startsWith("|") && lines[i].endsWith("|")) {
    //         if (!headerDetected) {
    //             headerDetected = true;
    //         } else if (!subheaderDetected) {
    //             subheaderDetected = true;
    //         } else if (!firstRowDetected) {
    //             firstRowDetected = true;
    //         } else {
    //             lastIndex = i;
    //             break;
    //         }
    //     } else if (lines[i].startsWith("|") && !lines[i].endsWith("|")) {
    //         lastIndex = Math.max(i - 1, 0);
    //         break;
    //     } else {
    //         lastIndex = i;
    //     }
    // }

    return parsedAnswer.substring(0, lastIndex);
}
