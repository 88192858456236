import { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { ChatAppFile, ChatAppResponse, getCitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useTranslation } from "react-i18next";
import { generateUuid } from "../../utils/uuid";
import { insideIframe } from "../../utils/iframe";

interface Props {
    tenant?: string;
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    lastAnswer: boolean;
    onCitationClicked: (filePath: string) => void;
    onRegenerateClicked: (id: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string, files: ChatAppFile[]) => void;
    showFollowupQuestions?: boolean;
    isDarkMode?: boolean;
}

const config = {
    ADD_ATTR: ["target", "rel"]
};

export const Answer = ({
    tenant,
    answer,
    isSelected,
    isStreaming,
    lastAnswer,
    onCitationClicked,
    onRegenerateClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    isDarkMode
}: Props) => {
    const { t, i18n } = useTranslation();
    const [isIframeChat, setIsIframeChat] = useState(insideIframe); //TODO change condition
    const followupQuestions = answer.choices[0].context.followup_questions;
    const messageContent = answer.choices[0].message.content;
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(messageContent, isStreaming, isIframeChat, answer.id, lastAnswer, onCitationClicked, onRegenerateClicked),
        [answer]
    );

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml, config);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 50 }}>
                    <AnswerIcon isDarkMode={isDarkMode} tenant={tenant} />
                    <div>
                        {!!parsedAnswer.buttons.length && (
                            <Stack.Item>
                                <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                    {parsedAnswer.buttons.map((x, i) => {
                                        return (
                                            <IconButton
                                                key={i}
                                                className={styles.button}
                                                iconProps={{ iconName: x.icon }}
                                                title={x.title}
                                                ariaLabel={x.title}
                                                style={x.show ?? !(isStreaming && lastAnswer) ? {} : { display: "none" }}
                                                onClick={() => {
                                                    if (x.onClick && x.id) {
                                                        const element = document.getElementById(x.id);
                                                        if (!element) {
                                                            return;
                                                        }
                                                        x.onClick(element);
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item className={styles.answer} grow>
                <div id={answer.id} className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <p className={styles.citationLearnMore}>{t("chat.extra.citations")}:</p>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!isStreaming && !!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <p className={styles.followupQuestionLearnMore}>{t("chat.extra.followup")}:</p>
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x, [])}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
