import { useRef, useState, useEffect, useCallback } from "react";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton, Slider } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";

import {
    chatApi,
    configApi,
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput,
    getConversationById,
    getAllConversations,
    HistoricalChatReponse,
    deleteConversationById,
    ChatAppLastQuestion,
    ChatAppFile,
    uploadFiles,
    deleteFiles,
    getExampleQuestions
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList, ExampleModel } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useLogin, getToken, isLoggedIn, requireAccessControl, type } from "../../authConfig";
import { VectorSettings } from "../../components/VectorSettings";
import { useMsal } from "@azure/msal-react";
import { TokenClaimsDisplay } from "../../components/TokenClaimsDisplay";
import { GPT4VSettings } from "../../components/GPT4VSettings";
import { useTranslation } from "react-i18next";
import { LogoutButton } from "../../components/LogoutButton";
import { HistoricalChat } from "../../components/HistoricalChat/HistoricalChat";
import { HistoricalButton } from "../../components/HistoricalButton";
import { useLocation, useNavigate } from "react-router-dom";
import { DarkModeButton } from "../../components/ToggleDarkModeButton";
import { checkUuid, generateUuid } from "../../utils/uuid";
import { insideIframe } from "../../utils/iframe";
import { useThrottleDebounceEffect } from "../../utils/timeout";
import ScrollToBottomButton from "../../components/ScrollToBottomButton/ScrollToBottomButton";
import { LoadingPage } from "../../components/Spinner/Spinner";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../layout/Layout";
import { use } from "i18next";

const Chat = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [tenant, isIframe, isDarkMode, setNavItems, toggleColorScheme, toggleLoading] = useOutletContext<OutletContext>();

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isHistoricalPanelOpen, setIsHistoricalPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [temperature, setTemperature] = useState<number>(0.3);
    const [minimumRerankerScore, setMinimumRerankerScore] = useState<number>(0);
    const [minimumSearchScore, setMinimumSearchScore] = useState<number>(0);
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [shouldStream, setShouldStream] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [vectorFieldList, setVectorFieldList] = useState<VectorFieldOptions[]>([VectorFieldOptions.Embedding]);
    const [useOidSecurityFilter, setUseOidSecurityFilter] = useState<boolean>(false);
    const [useGroupsSecurityFilter, setUseGroupsSecurityFilter] = useState<boolean>(false);
    const [gpt4vInput, setGPT4VInput] = useState<GPT4VInput>(GPT4VInput.Texts);
    const [useGPT4V, setUseGPT4V] = useState<boolean>(false);

    const lastQuestionRef = useRef<ChatAppLastQuestion>({ conversationId: "", question: "", files: [] });
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const answerRef = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);

    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [showGPT4VOptions, setShowGPT4VOptions] = useState<boolean>(false);
    const [showSemanticRankerOption, setShowSemanticRankerOption] = useState<boolean>(false);
    const [showVectorOption, setShowVectorOption] = useState<boolean>(false);
    const [allowFileUpload, setAllowFileUpload] = useState<boolean>(false);
    const [historicalChats, setHistoricalChats] = useState<HistoricalChatReponse[]>([]);
    const [controller, setController] = useState<AbortController>();
    const [files, setFiles] = useState<ChatAppFile[]>([]);
    const [overrideMessages, setOverrideMessages] = useState<boolean>(true);
    const [examples, setExamples] = useState<ExampleModel[]>([]);

    const getConfig = async () => {
        const token = client ? await getToken(client) : undefined;

        configApi(token).then(config => {
            setShowGPT4VOptions(config.showGPT4VOptions);
            setUseSemanticRanker(config.showSemanticRankerOption);
            setShowSemanticRankerOption(config.showSemanticRankerOption);
            setShowVectorOption(config.showVectorOption);
            if (!config.showVectorOption) {
                setRetrievalMode(RetrievalMode.Text);
            }
            setAllowFileUpload(config.allowFileUpload);
        });
    };

    const getLastResponse = () => {
        if (answers.length === 0) return "";
        if (streamedAnswers[streamedAnswers.length - 1].length > 0 && lastQuestionRef.current?.question === streamedAnswers[streamedAnswers.length - 1][0]) {
            return streamedAnswers[streamedAnswers.length - 1][1].choices[0].message.content;
        }
        if (answers[answers.length - 1].length > 0 && lastQuestionRef.current?.question === answers[answers.length - 1][0]) {
            return answers[answers.length - 1][1].choices[0].message.content;
        }
        return "";
    };

    const pathSegments = location.pathname.split("/");
    const c =
        pathSegments && pathSegments.length > 0 && pathSegments[pathSegments.length - 1] && checkUuid(pathSegments[pathSegments.length - 1])
            ? pathSegments[pathSegments.length - 1]
            : generateUuid();
    const [conversationId, setConversationId] = useState<string>(c);

    //listen conversationId and reload messages
    useEffect(() => {
        const redirectUri = `/chat/${conversationId}`;
        // check if we're same page
        if (location.pathname !== redirectUri) {
            navigate(redirectUri);
        }
    }, [conversationId]);

    useEffect(() => {
        if (lastQuestionRef.current.conversationId === conversationId) {
            return;
        }

        setIsFirstLoad(true);
        setIsHistoricalPanelOpen(false);

        const tokenPromise = getTokenPromise();
        const promises = [loadExampleQuestions(tokenPromise), loadCurrentChat(tokenPromise)];

        Promise.all(promises).then(() => {
            setIsFirstLoad(false);
        });
    }, [location, i18n.language]);

    useEffect(() => {
        setNavItems(navChildren);
        toggleLoading(isFirstLoad);
    }, [isFirstLoad]);

    const getTokenPromise = () => {
        return client ? getToken(client) : new Promise<string | undefined>(() => undefined);
    };
    const loadExampleQuestions = async (tokenPromise: Promise<string | undefined>) => {
        try {
            const examples = await getExampleQuestions(6, i18n.language, await tokenPromise);
            setExamples(examples);
        } catch (error) {
            setExamples([]);
        }
    };
    const loadCurrentChat = async (tokenPromise: Promise<string | undefined>) => {
        try {
            const conversation = await getConversationById(conversationId, await tokenPromise);
            loadConversation(conversation);
        } catch (error) {
            console.error(error);
        }
    };

    const getChats = async (tokenPromise: Promise<string | undefined>) => {
        let conversations: HistoricalChatReponse[] = [];
        try {
            conversations = (await getAllConversations(100, await tokenPromise)) || [];
        } catch (error) {
            console.error(error);
        } finally {
            setHistoricalChats(conversations);
        }
    };
    useThrottleDebounceEffect(
        () => {
            return getChats(getTokenPromise());
        },
        5000,
        30000,
        [answers]
    );

    const deleteConversation = async (id: string) => {
        if (id == conversationId) {
            clearChat();
        }

        try {
            const tokenPromise = getTokenPromise();
            await deleteConversationById(id, await tokenPromise);
            await getChats(tokenPromise);
        } catch (error) {
            console.log(error);
        }
    };

    const loadConversationMessages = (messages: any[], thoughts: any) => {
        let results: [user: string, response: ChatAppResponse][] = [];

        let count = 0;
        let tuples = [];
        while (count < messages.length) {
            const message = messages[count];
            count++;
            switch (message.role) {
                case "user":
                    tuples.push([message.content, null]);
                    continue;
                case "assistant":
                    const lastTuple = tuples[tuples.length - 1];
                    lastTuple[1] = {
                        id: generateUuid(),
                        choices: [
                            {
                                message: {
                                    content: message.content,
                                    role: message.role
                                },
                                session_state: message.session_state,
                                context: message.context || {
                                    data_points: [],
                                    thoughts: ""
                                },
                                finish_reason: message.finish_reason || null,
                                index: 0
                            }
                        ]
                    };
                    results.push([lastTuple[0], lastTuple[1]]);
                default:
                    continue;
            }
        }

        setAnswers(results);
        setStreamedAnswers(results);
    };

    const scrollToBottom = () => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    };

    const loadConversationFiles = (files: any) => {
        const results: ChatAppFile[] = files.map((file: any) => {
            return {
                id: file.id,
                data: {
                    name: file
                },
                status: "uploaded"
            };
        });
        setFiles(results);
    };

    const loadConversation = (responses: any) => {
        if (!responses) {
            return;
        }

        cancelRequest();
        clearErros();

        loadConversationMessages(responses?.messages ?? [], responses?.thoughts);
        loadConversationFiles(responses?.files ?? []);

        lastQuestionRef.current.conversationId = conversationId;
        setIsHistoricalPanelOpen(false);
        setIsLoading(false);
    };

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], setAnswers: Function, responseBody: ReadableStream<any>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        id: generateUuid(),
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatAppResponse;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            id: generateUuid(),
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };

    const client = useLogin() ? useMsal().instance : undefined;

    const cancelRequest = () => {
        if (!controller) {
            return;
        }

        controller.abort();
        setController(undefined);
    };

    const makeApiRequest = async (question: string, files: ChatAppFile[]) => {
        if (error) {
            let ste = streamedAnswers;
            ste.pop();
            setStreamedAnswers(ste);
        }
        lastQuestionRef.current.conversationId = conversationId;
        lastQuestionRef.current.question = question;
        lastQuestionRef.current.files = files;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        const token = client ? await getToken(client) : undefined;

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a[1].choices[0].message.content, role: "assistant" }
            ]);

            const abortController = new AbortController();
            setController(abortController);
            const request: ChatAppRequest = {
                conversation_id: conversationId,
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                signal: abortController.signal,
                context: {
                    overrides: {
                        prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
                        exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
                        top: retrieveCount,
                        temperature: temperature,
                        minimum_reranker_score: minimumRerankerScore,
                        minimum_search_score: minimumSearchScore,
                        retrieval_mode: retrievalMode,
                        semantic_ranker: useSemanticRanker,
                        semantic_captions: useSemanticCaptions,
                        suggest_followup_questions: useSuggestFollowupQuestions,
                        use_oid_security_filter: useOidSecurityFilter,
                        use_groups_security_filter: useGroupsSecurityFilter,
                        vector_fields: vectorFieldList,
                        use_gpt4v: useGPT4V,
                        gpt4v_input: gpt4vInput,
                        override_messages: overrideMessages
                    }
                },
                // ChatAppProtocol: Client must pass on any session state received from the server
                session_state: answers.length ? answers[answers.length - 1][1].choices[0].session_state : null
            };

            const response = await chatApi(request, token);
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, setAnswers, response.body);
                setAnswers([...answers, [question, parsedResponse]]);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
            }
        } catch (e) {
            setError(e);
            if (isLoading) {
                clearErros();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const uploadFile = async (files: ChatAppFile[]) => {
        let finalStatus: "uploaded" | "error" = "uploaded";
        try {
            const pendingFiles = files?.filter(f => f.status === "none");
            if (pendingFiles.length === 0) {
                finalStatus = files?.some(f => f.status === "error") ? "error" : finalStatus;
                return;
            }

            setIsFileLoading(true);
            questionRefreshToken.current = generateUuid();
            error && setError(undefined);
            setActiveCitation(undefined);
            setActiveAnalysisPanelTab(undefined);

            pendingFiles.forEach(f => {
                f.status = "uploading";
            });

            const token = client ? await getToken(client) : undefined;
            await uploadFiles(conversationId, pendingFiles, token);
        } catch (e) {
            console.error(e);
            finalStatus = "error";

            if (isLoading) {
                clearErros();
            }
        } finally {
            files?.forEach(f => {
                f.status = finalStatus;
            });
            setFiles(files);
            setIsFileLoading(false);
        }
    };

    const deleteFile = async (files: ChatAppFile[]) => {
        let finalStatus: "removed" | "error" = "removed";
        try {
            error && setError(undefined);
            questionRefreshToken.current = generateUuid();
            setIsFileLoading(true);
            setActiveCitation(undefined);
            setActiveAnalysisPanelTab(undefined);

            const fileNames = files.map(f => f.data.name);
            files?.forEach(f => {
                f.status = "removing";
            });

            const token = client ? await getToken(client) : undefined;
            await deleteFiles(conversationId, fileNames, token);
        } catch (e) {
            console.error(e);
            finalStatus = "error";

            if (isLoading) {
                clearErros();
            }
        } finally {
            files?.forEach(f => {
                f.status = finalStatus;
            });
            setFiles(files.filter(f => f.status !== "removed"));
            setIsFileLoading(false);
        }
    };

    const makeRetryRequest = async (question: string, files: ChatAppFile[], id: string) => {
        let ste: [user: string, response: ChatAppResponse][] = answers;
        while (ste.length > 0 && ste[ste.length - 1][1].id !== id) {
            ste.pop();
        }
        setStreamedAnswers(ste);
        ste.pop();

        setAnswers(ste);

        makeApiRequest(question, files);
    };

    const regenerateLastAnswer = async (id: string) => {
        setOverrideMessages(true);
        const lastAnswer = answers.filter(a => a[1].id === id);
        if (!lastAnswer) {
            return;
        }
        makeRetryRequest(lastAnswer[0][0], files, id);
        setOverrideMessages(false);
    };

    const clearChat = () => {
        if (answers.length == 0) return;
        lastQuestionRef.current.conversationId = generateUuid();
        lastQuestionRef.current.question = "";
        lastQuestionRef.current.files = [];
        // lastQuestionRef.error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setFiles([]);
        setIsLoading(false);
        setIsStreaming(false);
        setIsFileLoading(false);
        setConversationId(generateUuid());
        setIsFirstLoad(true);
    };

    const clearErros = () => {
        lastQuestionRef.current.conversationId = "";
        lastQuestionRef.current.question = "";
        lastQuestionRef.current.files = [];
        setError(undefined);
        setFiles([]);
        setIsFirstLoad(true);
    };

    useEffect(() => scrollToBottom(), [isLoading, isStreaming, isFileLoading, lastQuestionRef.current.conversationId]);
    useEffect(() => {
        function isScrollAtBottom(container: HTMLDivElement | null) {
            if (!container) {
                return false;
            }

            const scrollTop = container.scrollTop;
            const scrollHeight = container.scrollHeight;
            const clientHeight = container.clientHeight;
            return scrollHeight - scrollTop === clientHeight;
        }
        if (isScrollAtBottom(chatMessageStreamEnd.current)) {
            scrollToBottom();
        }
    }, [answers, streamedAnswers]);
    useEffect(() => {
        getConfig();
    }, []);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onTemperatureChange = (
        newValue: number,
        range?: [number, number],
        event?: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent | React.KeyboardEvent
    ) => {
        setTemperature(newValue);
    };

    const onMinimumSearchScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumSearchScore(parseFloat(newValue || "0"));
    };

    const onMinimumRerankerScoreChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setMinimumRerankerScore(parseFloat(newValue || "0"));
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onShouldStreamChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setShouldStream(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseOidSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseOidSecurityFilter(!!checked);
    };

    const onUseGroupsSecurityFilterChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseGroupsSecurityFilter(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example, []);
    };

    const onShowCitation = (citation: string, index: number) => {
        // open new tab when citation is clicked
        window.open(citation, "_blank");

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const [height, setHeight] = useState<string | undefined>(undefined);
    const onChangeQuestion = (question?: string, files: ChatAppFile[] = [], height: number | undefined = undefined) => {
        const answerHeight = () => {
            const realHeight = height || (answerRef.current ? answerRef.current.clientHeight : undefined);
            return realHeight != null && realHeight != undefined ? Math.max(60, realHeight) : undefined;
        };
        const filesHeight = () => {
            return files.length > 0 && files.every(x => x.status != "removed") ? 60 : 0;
        };
        const sumHeight = (values: (number | undefined)[]) => {
            if (values.some(v => v == undefined)) {
                return undefined;
            }
            return values.reduce((acc: number, v) => acc + (v || 0), 0);
        };

        let expectedHeight = sumHeight([answerHeight(), filesHeight()]);
        setHeight(expectedHeight ? expectedHeight + 25 + "px" : undefined);
    };

    useEffect(() => {
        onChangeQuestion();
    }, [isFirstLoad]);

    const questionRefreshToken = useRef<string | undefined>(undefined);
    useEffect(() => {
        questionRefreshToken.current = generateUuid();
    }, [isFirstLoad, isLoading, isStreaming, isFileLoading, lastQuestionRef, files, answerRef]);

    // create a new variable (that allows to detect changes) with navChildren
    const [navChildren] = useState<JSX.Element[]>([]);
    useEffect(() => {
        const navChildren = [
            <div id="chat-wrapper" className={isIframe ? `${styles.commandsContainer} ${styles.commandsContainerSticky}` : styles.commandsContainer}>
                {useLogin() && type() !== "msal" && <LogoutButton className={`${isIframe ? styles.displayNone : ""} ${styles.commandButton}`} />}
                {!insideIframe && (
                    <DarkModeButton
                        className={`${styles.commandButton} ${isIframe ? styles.displayNone : ""}`}
                        disabled={isLoading || isStreaming || isFileLoading || isFirstLoad}
                        isDarkMode={isDarkMode}
                        onChange={_ => toggleColorScheme()}
                    />
                )}
                <ClearChatButton
                    className={styles.commandButton}
                    onClick={clearChat}
                    disabled={(!lastQuestionRef.current.question && answers.length == 0) || isLoading || isStreaming || isFileLoading}
                />
                <HistoricalButton
                    className={styles.commandButton}
                    onClick={() => setIsHistoricalPanelOpen(!isHistoricalPanelOpen)}
                    disabled={isLoading || isStreaming || isFileLoading || isFirstLoad}
                />
                {false && (
                    <SettingsButton
                        className={`${styles.commandButton} ${isIframe ? styles.displayNone : ""}`}
                        onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)}
                        disabled={isLoading || isStreaming || isFileLoading || isFirstLoad}
                    />
                )}
            </div>
        ];
        setNavItems(navChildren);
    }, [location, isFirstLoad, isDarkMode]);

    return (
        <ScrollToBottomButton
            className={styles.container}
            show={!(isFirstLoad || (!lastQuestionRef.current.question && answers.length == 0))}
            right={"50%"}
            bottom={height}
        >
            <div className={isIframe ? `${styles.chatRoot} ${styles.chatRootIframe}` : styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {isFirstLoad || (!lastQuestionRef.current.question && answers.length == 0) ? (
                        <div className={styles.chatEmptyState}>
                            {!isIframe && <h1 className={`${styles.chatEmptyStateTitle} ${styles.chatEmpty}`}>{t("main.header")}</h1>}
                            <h2 className={`${styles.chatEmptyStateSubtitle} ${styles.chatEmpty}`}>{t("main.subheader")}</h2>
                            <ExampleList examples={examples} onExampleClicked={onExampleClicked} useGPT4V={useGPT4V} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={streamedAnswer[0]} />

                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={true}
                                                key={index}
                                                tenant={tenant}
                                                answer={streamedAnswer[1]}
                                                isSelected={false}
                                                lastAnswer={index == streamedAnswers.length - 1}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onRegenerateClicked={id => regenerateLastAnswer(id)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={(q, files) => makeApiRequest(q, files)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                isDarkMode={isDarkMode}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer[1]}
                                                tenant={tenant}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                lastAnswer={index == answers.length - 1}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onRegenerateClicked={id => regenerateLastAnswer(id)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={(q, files) => makeApiRequest(q, files)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                isDarkMode={isDarkMode}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && !isFileLoading && lastQuestionRef.current.question && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current?.question ?? ""} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading isDarkMode={isDarkMode} tenant={tenant} />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current?.question ?? ""} />
                                    <div className={styles.chatMessageGpt}>
                                        <AnswerError
                                            error={t("chat.error")}
                                            text={getLastResponse()}
                                            isDarkMode={isDarkMode}
                                            tenant={tenant}
                                            onRetry={() => makeApiRequest(lastQuestionRef?.current?.question ?? "", lastQuestionRef?.current?.files ?? [])}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div ref={answerRef} className={`${styles.chatInput} ${isFirstLoad ? styles.chatInIframe : ""}`}>
                        <QuestionInput
                            className={`${styles.questionInput}`}
                            clearOnSend
                            placeholder={t("chat.input.placeholder")}
                            maxLength={1000}
                            disabled={isLoading || isStreaming || isFileLoading}
                            isLoading={isLoading || isStreaming}
                            onChange={(question, files, height) => onChangeQuestion(question, files, height)}
                            onSend={(question, files) => makeApiRequest(question, files)}
                            onUploadFile={uploadFile}
                            onRemoveFile={(file: ChatAppFile) => deleteFile([file])}
                            cancelRequest={cancelRequest}
                            inputFiles={files}
                            allowFileUpload={allowFileUpload}
                            color="var(--color-primary)"
                            refreshToken={questionRefreshToken.current}
                            clearToken={lastQuestionRef.current.conversationId}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText={t("settings.developer.header")}
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    styles={{
                        root: {
                            color: "var(--color-font)"
                        },
                        main: {
                            backgroundColor: "var(--color-background-secondary)",
                            color: "var(--color-font)"
                        },
                        commands: {
                            backgroundColor: "var(--color-background-secondary)",
                            color: "var(--color-font)"
                        },
                        headerText: {
                            color: "var(--color-font)"
                        },
                        footerInner: {
                            backgroundColor: "var(--color-background-secondary)",
                            color: "var(--color-font)",
                            display: "flex",
                            justifyContent: "flex-end"
                        }
                    }}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    onRenderFooterContent={() => (
                        <DefaultButton
                            styles={{
                                root: {
                                    backgroundColor: "var(--color-primary)",
                                    color: "var(--color-background)",
                                    borderRadius: "21px",
                                    fontFamily: "Roboto, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 700
                                },
                                rootHovered: {
                                    backgroundColor: "var(--color-secondary)",
                                    color: "var(--color-font)"
                                },
                                rootPressed: {
                                    backgroundColor: "var(--color-secondary)",
                                    color: "var(--color-font)"
                                }
                            }}
                            onClick={() => setIsConfigPanelOpen(false)}
                        >
                            {t("common.close")}
                        </DefaultButton>
                    )}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label={t("settings.developer.promptTemplate")}
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <Slider
                        className={styles.chatSettingsSeparator}
                        label={t("settings.developer.temperature")}
                        min={0}
                        max={1}
                        step={0.1}
                        defaultValue={temperature}
                        onChange={onTemperatureChange}
                        showValue
                        snapToStep
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum search score"
                        min={0}
                        step={0.01}
                        defaultValue={minimumSearchScore.toString()}
                        onChange={onMinimumSearchScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Minimum reranker score"
                        min={1}
                        max={4}
                        step={0.1}
                        defaultValue={minimumRerankerScore.toString()}
                        onChange={onMinimumRerankerScoreChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label={t("settings.developer.retrieveCount")}
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label={t("settings.developer.excludeCategory")} onChange={onExcludeCategoryChanged} />

                    {showSemanticRankerOption && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSemanticRanker}
                            label={t("settings.developer.useSemanticRanker")}
                            onChange={onUseSemanticRankerChange}
                        />
                    )}
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label={t("settings.developer.useSemanticCaptions")}
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label={t("settings.developer.useSuggestFollowupQuestions")}
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />

                    {showGPT4VOptions && (
                        <GPT4VSettings
                            gpt4vInputs={gpt4vInput}
                            isUseGPT4V={useGPT4V}
                            updateUseGPT4V={useGPT4V => {
                                setUseGPT4V(useGPT4V);
                            }}
                            updateGPT4VInputs={inputs => setGPT4VInput(inputs)}
                        />
                    )}

                    {showVectorOption && (
                        <VectorSettings
                            showImageOptions={useGPT4V && showGPT4VOptions}
                            updateVectorFields={(options: VectorFieldOptions[]) => setVectorFieldList(options)}
                            updateRetrievalMode={(retrievalMode: RetrievalMode) => setRetrievalMode(retrievalMode)}
                        />
                    )}

                    {useLogin() && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useOidSecurityFilter || requireAccessControl()}
                            label={t("settings.developer.useOidSecurityFilter")}
                            disabled={!isLoggedIn(client) || requireAccessControl()}
                            onChange={onUseOidSecurityFilterChange}
                        />
                    )}
                    {useLogin() && (
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useGroupsSecurityFilter || requireAccessControl()}
                            label={t("settings.developer.useGroupsSecurityFilter")}
                            disabled={!isLoggedIn(client) || requireAccessControl()}
                            onChange={onUseGroupsSecurityFilterChange}
                        />
                    )}

                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={shouldStream}
                        label={t("settings.developer.shouldStream")}
                        onChange={onShouldStreamChange}
                    />
                    {useLogin() && type() === "msal" && <TokenClaimsDisplay />}
                </Panel>
                <HistoricalChat
                    deleteConversation={deleteConversation}
                    isHistoricalPanelOpen={isHistoricalPanelOpen}
                    setIsHistoricalPanelOpen={setIsHistoricalPanelOpen}
                    conversationId={conversationId}
                    setConversationId={setConversationId}
                    historicalChats={historicalChats}
                    isIframeChat={isIframe}
                ></HistoricalChat>
            </div>
        </ScrollToBottomButton>
    );
};

export default Chat;
