import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { insideIframe } from "./iframe";

const tenantKey = "x-tenant";
const tenantStoreKey = "currentTenant";

/**
 * Custom hook to get and store the current tenant.
 * - First priority: 'tenant_id' query parameter.
 * - Second priority: Last saved value in localStorage.
 * - Third priority: Default value based on the location page.
 */
const useTenant: () => [string | undefined, Dispatch<SetStateAction<string | undefined>>] = () => {
    const [tenant, setTenant] = useState<string | undefined>(() => {
        // Attempt to read the 'tenant_id' from the URL query parameters.
        const urlParams = new URLSearchParams(window.location.search);
        const tenantFromQueryParam = urlParams.get(tenantKey);

        // Fallback to the value from localStorage if it exists.
        const tenantFromLocalStorage = localStorage.getItem(tenantStoreKey);

        // Use a default value based on the location
        const defaultTenant = window.location.hostname;

        // Put all sources, check if they are equal and return the value.
        const sources = [tenantFromQueryParam, tenantFromLocalStorage, insideIframe ? null : defaultTenant].filter(x => x);
        const uniqueSources = Array.from(new Set(sources));
        return (uniqueSources.length === 1 ? uniqueSources[0] : insideIframe ? null : defaultTenant) || undefined;
    });

    useEffect(() => {
        // if tenant has changed, save it to localStorage
        const oldTenant = localStorage.getItem(tenantStoreKey);
        if (tenant && (!oldTenant || oldTenant === tenant)) {
            localStorage.setItem(tenantStoreKey, tenant);
        } else {
            if (tenant) {
                localStorage.removeItem(tenantStoreKey);
                setTenant(undefined);
            }
        }
    }, [tenant]);

    return [tenant, setTenant];
};

export const addTenantToUrl = (url: string, tenant: string | undefined) => {
    if (!tenant) {
        return url;
    }
    const urlObj = new URL(url, window.location.origin);
    if (!urlObj.searchParams.has(tenantKey) && tenant) {
        urlObj.searchParams.append(tenantKey, tenant);
    }
    return urlObj.toString();
};

export const getTenant = (): string | undefined => {
    return localStorage.getItem(tenantStoreKey) || undefined;
};

export const removeTenant = () => {
    localStorage.removeItem(tenantStoreKey);
};

export default useTenant;
