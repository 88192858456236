import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faFile, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./FileCard.module.css";

interface FileCardProps {
    className?: string;
    color?: string;
    fileName: string;
    status?: "none" | "uploading" | "uploaded" | "error" | "removing" | "removed";
    tooltip?: string;
    onRemove: () => void;
}

export const FileCard: FC<FileCardProps> = ({ className, color, fileName, status, tooltip, onRemove }) => {
    const renderFileStateIcon = () => {
        switch (status) {
            case "uploading":
            case "removing":
                return <FontAwesomeIcon icon={faSpinner} spin className={styles.stateIcon} title={tooltip} color={color} />;
            case "uploaded":
                return <FontAwesomeIcon icon={faCheck} className={styles.stateIcon} title={tooltip} color={color} />;
            case "error":
                return <FontAwesomeIcon icon={faExclamationTriangle} className={styles.stateIcon} title={tooltip} color="red" />;
            default:
                return null;
        }
    };

    return (
        <div className={`${styles.fileCard} ${className}`}>
            <FontAwesomeIcon icon={faFile} className={styles.fileIcon} color={color} />
            <span className={styles.fileName}>{fileName}</span>
            {renderFileStateIcon()}
            <button className={styles.removeButton} onClick={onRemove}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
    );
};
