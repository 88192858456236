import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Logo.module.css";
import TenantImage from "../../components/Tenant/TenantImage/TenantImage";

interface TenantImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    tenant?: string;
}
interface LogoHeaderProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    tenant?: string;
    isDarkMode?: boolean;
}

const logoPath = "/assets/img/";
export const Logo: React.FC<LogoHeaderProps> = ({ tenant, isDarkMode, ...props }) => {
    const [path, setPath] = React.useState(logoPath + "logo-light");

    useEffect(() => {
        setPath(logoPath + (isDarkMode ? "logo-dark" : "logo-light"));
    }, [isDarkMode]);

    return (
        <picture className={props.className}>
            <TenantImage {...props} src={path} alt="Logo" aria-label="Main page" tenant={tenant} />
        </picture>
    );
};

export const LogoHeader: React.FC<LogoHeaderProps> = ({ tenant, isDarkMode, ...props }) => {
    return (
        <Link to="/" className={`${styles.headerTitleContainer} ${props.className}`}>
            <Logo className={`${props.className} ${styles.leftLogo}`} isDarkMode={isDarkMode} tenant={tenant} />
        </Link>
    );
};
