import { useTranslation } from "react-i18next";
import styles from "./Spinner.module.css";
import { ThreeDots } from "react-loader-spinner";

interface Props {}

export const LoadingPage = ({}: Props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.loadingContainer}>
            <div className={styles.spinnerContainer}>
                <ThreeDots height="80" width="80" color="var(--color-primary)" ariaLabel="circles-loading" visible={true} />
            </div>
        </div>
    );
};
