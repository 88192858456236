import { EventTypes, emitEvent } from "../embed";

// Function to download table as CSV
export function redirectTo(spanId: string, isExternal: boolean) {
    const element = document.getElementById(spanId) as HTMLLinkElement;
    const url = element.href;
    //Is is external link then open in new tab else event redirect
    if (isExternal) {
        window.open(url, "_blank");
        return;
    }
    emitEvent(EventTypes.EVENT_REDIRECT, "", url);
}
