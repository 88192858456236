import { useEffect, useState } from "react";
import { isJwtExpired, useAuthToken } from "../../auth/token";
import { useLocation, useNavigate } from "react-router-dom";
import LoginWrapper from "./components/LoginWrapper/LoginWrapper";
import { getAccessTokenByCode, getAccessTokenByRefreshToken } from "./auth";
import { EventTypes, emitEvent } from "../../embed";

const AuthProvider = (props: any) => {
    const refreshAccessToken = async () => {
        if (!refreshToken) {
            return;
        }

        if (refresingToken) {
            return;
        }

        // check if refresh token is running
        setRefreshingToken(true);
        setIsLoading(true);

        try {
            const { access_token, refresh_token } = await getAccessTokenByRefreshToken({
                tokenUrl: tokenUri,
                clientId,
                refreshToken
            });

            if (access_token) {
                setAccessToken(access_token);
            }
            if (refresh_token) {
                setRefreshToken(refresh_token);
            }
        } catch (error) {
            console.error("Token refresh failed:", error);
            logout();
        } finally {
            setRefreshingToken(false);
            setIsLoading(false);
        }
    };

    const { tenant, children, authorizeUri, tokenUri, logoutUri, clientId, scope, responseType, redirectUri } = props;
    const [accessToken, setAccessToken, removeAccessToken, refreshToken, setRefreshToken, removeRefreshToken, isAuthenticated] =
        useAuthToken(refreshAccessToken);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [refresingToken, setRefreshingToken] = useState(false);

    useEffect(() => {
        emitEvent(EventTypes.EVENT_IFRAME_LOADED);
    }, []);

    const onSuccess = async (response: any) => {
        try {
            if (!response.access_token || isJwtExpired(response.access_token)) {
                if (!response.code) {
                    return;
                }

                if (isAuthenticated) {
                    return;
                }

                const { access_token, refresh_token } = await getAccessTokenByCode({
                    tokenUrl: tokenUri,
                    clientId,
                    code: response.code,
                    redirectUri
                });
                response = {
                    ...response,
                    access_token,
                    refresh_token
                };
            }

            if (response.access_token) {
                setAccessToken(response.access_token);
            }
            if (response.refresh_token) {
                setRefreshToken(response.refresh_token);
            }
        } catch (error) {
            console.error("Token refresh failed:", error);
            logout();
        } finally {
            setIsLoading(false);
        }
    };

    const onFailure = async (error: any) => {
        setError(error);
        setRefreshingToken(false);
        setIsLoading(false);
    };

    const onClick = async () => {
        setIsLoading(true);
    };

    const onLoading = async (isLoading: boolean) => {
        setIsLoading(isLoading);
    };

    const navigate = useNavigate();
    const logout = async () => {
        setError(null);
        removeAccessToken();
        removeRefreshToken();
        setRefreshingToken(false);
        setIsLoading(false);

        if (logoutUri) {
            window.location.href = logoutUri;
        } else {
            navigate("/");
        }
    };

    const location = useLocation();
    const refreshLayout = () => {
        let layout = <div></div>;

        if (isAuthenticated && children) {
            layout = <div className="container">{children}</div>;
        }

        if (!isAuthenticated) {
            layout = (
                <LoginWrapper
                    tenant={tenant}
                    clientId={clientId}
                    authorizeUri={authorizeUri}
                    scope={scope}
                    redirectUri={redirectUri}
                    isLoading={isLoading}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    onClick={onClick}
                    onLoading={onLoading}
                ></LoginWrapper>
            );
        }

        return layout;
    };

    let layout = refreshLayout();
    useEffect(() => {
        if (location.pathname === "/logout") {
            logout();
            return;
        }

        layout = refreshLayout();
    }, [location, isAuthenticated, isLoading, error]);

    return layout;
};

export default AuthProvider;
