import { Button } from "@fluentui/react-components";

import styles from "./ToggleDarkModeButton.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

interface Props {
    className?: string;
    isDarkMode?: boolean;
    onChange?: (isDarkMode: boolean) => void;
    disabled?: boolean;
}

export const DarkModeButton = ({ className, disabled, onChange, isDarkMode }: Props) => {
    const { t, i18n } = useTranslation();

    const toggleDarkMode = (value: boolean) => {
        if (value === isDarkMode) return;
        onChange && onChange(value);
    };

    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Button disabled={disabled} icon={<FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />} onClick={_ => toggleDarkMode(!isDarkMode)}></Button>
        </div>
    );
};
