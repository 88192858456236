import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDebounceEffect } from "../../utils/timeout";

interface TypewriterProps {
    className?: string;
    sentences: string[];
    typingSpeed?: number;
    sentenceDelay?: number;
    randomSentences?: boolean;
}

const randomIndex = (length: number) => Math.floor(Math.random() * length);
const defaultTypingSpeed = 60;
const defaultSentenceDelay = 2000;

const Typewriter: React.FC<TypewriterProps> = ({ className, sentences, typingSpeed, sentenceDelay, randomSentences }) => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(randomSentences ? randomIndex(sentences.length) : 0);
    const [displayedText, setDisplayedText] = useState("");
    const [isTyping, setIsTyping] = useState(true);

    const typeNextSentence = () => {
        const currentSentence = t(sentences[index]);
        if (displayedText.length < currentSentence.length) {
            setTimeout(() => {
                setDisplayedText(currentSentence.slice(0, displayedText.length + 1));
            }, typingSpeed || defaultTypingSpeed);
        } else {
            setIsTyping(false);
        }
    };

    useDebounceEffect(
        () => {
            if (!isTyping) {
                const nextIndex = randomSentences ? randomIndex(sentences.length) : (index + 1) % sentences.length;
                setIndex(nextIndex);
                setDisplayedText("");
                setIsTyping(true);
            }
        },
        sentenceDelay || defaultSentenceDelay,
        [isTyping]
    );

    useEffect(() => {
        if (isTyping) {
            typeNextSentence();
        }
    }, [displayedText, isTyping, index, t]);

    return <p className={className}>{displayedText}</p>;
};

export default Typewriter;
