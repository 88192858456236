import React, { useEffect, useState } from "react";
import { addTenantToUrl } from "../../../utils/tenant";

interface TenantImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    tenant?: string;
}
const TenantImage = ({ tenant, ...props }: TenantImageProps) => {
    const [tenantSrc, setTenantSrc] = useState<string | undefined>(props.src);

    useEffect(() => {
        const tenantSrc = props && props.src ? addTenantToUrl(props.src, tenant) : undefined;
        setTenantSrc(tenantSrc);
    }, [props, tenant]);

    return <img {...props} src={tenantSrc} />;
};

export default TenantImage;
