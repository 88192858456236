import React, { Component, useEffect, useRef, useState } from "react";

import styles from "./LoginWrapper.module.css";
import { Trans, useTranslation } from "react-i18next";
import LoginOAuth2 from "../LoginOAuth2/LoginOAuth2.jsx";
import { LogoHeader } from "../../../logo/Logo";
import { insideIframe } from "../../../../utils/iframe";
import Typewriter from "../../../../components/Typewriter/Typewriter";
import { useColorScheme } from "../../../../utils/dark";

interface LoginButtonProps {
    tenant?: string;
    clientId: string;
    authorizeUri: string;
    scope: string;
    redirectUri: string;
    isLoading?: boolean;
    onSuccess: (response: any) => void;
    onFailure: (error: any) => void;
    onClick: () => void;
    onLoading: (isLoading: boolean) => void;
}

const iframeSandBox = [
    "allow-forms",
    "allow-modals",
    "allow-orientation-lock",
    "allow-pointer-lock",
    "allow-popups",
    "allow-popups-to-escape-sandbox",
    "allow-presentation",
    "allow-same-origin",
    "allow-scripts",
    "allow-storage-access-by-user-activation",
    "allow-top-navigation",
    "allow-top-navigation-by-user-activation"
];
const backgroundPath = "/assets/img/";
const LoginWrapper: React.FC<LoginButtonProps> = ({
    tenant,
    clientId,
    authorizeUri,
    scope,
    redirectUri,
    isLoading,
    onSuccess,
    onFailure,
    onClick,
    onLoading
}) => {
    const { t, i18n } = useTranslation();
    const [isDarkMode, toggleColorScheme] = useColorScheme();
    const [backgroundUri, setBackgroundUri] = useState(backgroundPath + "background-image");
    const [iframeName, setIframeName] = useState("iframe-auth");
    const [idPopup, setIdPopup] = useState<string | undefined>(insideIframe ? iframeName : undefined);
    const [firstLoad, setFirstLoad] = useState(insideIframe);

    const resetAutomaticLogin = () => {
        setFirstLoad(false);
        setIdPopup(undefined);
        loginButtonRef.current?.focus();
    };

    const customOnRequest = (config: any) => {
        resetAutomaticLogin();
    };

    const customOnSuccess = (response: any) => {
        resetAutomaticLogin();
        onSuccess(response);
    };

    const customOnFailure = (error: any) => {
        resetAutomaticLogin();
        onFailure(error);
    };

    const customOnClick = () => {
        if (!firstLoad) {
            if (isLoading) {
                resetAutomaticLogin();
            } else {
                onClick();
            }
        }
    };

    // focus on the login button when the enter key is pressed
    const loginButtonRef = useRef<HTMLButtonElement>(null);
    // useEffect(() => {
    //     const handleKeyPress = (event: KeyboardEvent) => {
    //         if (event.key === "Enter") {
    //             loginButtonRef.current?.click();
    //         }
    //     };

    //     document.addEventListener("keydown", handleKeyPress);
    //     return () => {
    //         document.removeEventListener("keydown", handleKeyPress);
    //     };
    // }, []);

    // focus on the login button at the init
    useEffect(() => {
        loginButtonRef.current?.focus();
    }, [loginButtonRef]);
    // initial load
    const sentences: string[] = Array.from({ length: 10 }, (_, i) => `login.sentences.key${i + 1}`);
    return (
        <div
            className={`${styles.centeredContainer} ${backgroundUri ? styles.background : ""}`}
            style={{ backgroundImage: backgroundUri ? `url(${backgroundUri})` : undefined }}
        >
            <div className={`${styles.loginButtonContainer}`}>
                <div className={styles.logo}>
                    <LogoHeader isDarkMode={isDarkMode} tenant={tenant} />
                </div>
                <div className={`${styles.placeholder} ${isLoading ? styles.autoLogin : ""}`}>
                    <Typewriter className={styles.types} sentences={sentences} randomSentences={true} />
                </div>
                <div className={`${styles.placeholder} ${styles.redirect} ${!isLoading ? styles.autoLogin : ""}`}>
                    <Trans i18nKey="login.autoLogin" components={{ newLine: <br /> }} />
                </div>
                <LoginOAuth2
                    id={idPopup}
                    buttonRef={loginButtonRef}
                    className={`${styles.loginButton} ${isLoading ? styles.autoLogin : ""}`}
                    buttonLabel={!isLoading ? t("common.login") : t("common.cancel")}
                    clientId={clientId}
                    authorizeUri={authorizeUri}
                    scope={scope}
                    redirectUri={redirectUri}
                    onRequest={customOnRequest}
                    onSuccess={customOnSuccess}
                    onFailure={customOnFailure}
                    onClick={customOnClick}
                />
                <div className={styles.footer} dangerouslySetInnerHTML={{ __html: t("login.footer", { privacyUrl: "#", termsUrl: "#" }) }}></div>
                <iframe name={iframeName} className={`${styles.iframe} ${isLoading ? styles.hidden : ""}`} sandbox={iframeSandBox.join(" ")}>
                    <p>Your browser does not support iframes.</p>
                </iframe>
            </div>
        </div>
    );
};

export default LoginWrapper;
