import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { DefaultButton, Stack, TextField, on } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea, Input } from "@fluentui/react-components";
import { Send28Filled } from "@fluentui/react-icons";
import { isLoggedIn, requireAccessControl } from "../../authConfig";

import styles from "./QuestionInput.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faLongArrowAltUp, faStopCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FileUploadButton } from "../FileUpload/FileUpload";
import { FileCard } from "../FileCard/FileCard";
import { ChatAppFile } from "../../api";
import { use } from "i18next";

interface Props {
    className?: string;
    color?: string;
    onChange?: (question: string, files: ChatAppFile[], height: number | undefined) => void;
    onSend: (question: string, files: ChatAppFile[]) => void;
    onUploadFile: (files: ChatAppFile[]) => void;
    onRemoveFile: (file: ChatAppFile) => void;
    cancelRequest: () => void;
    isLoading: boolean;
    disabled: boolean;
    initQuestion?: string;
    placeholder?: string;
    maxLength?: number;
    clearOnSend?: boolean;
    allowFileUpload?: boolean;
    inputFiles?: ChatAppFile[];
    refreshToken?: string;
    clearToken?: string;
}

export const QuestionInput = ({
    className,
    color,
    onChange,
    onSend,
    onUploadFile,
    onRemoveFile,
    cancelRequest,
    isLoading,
    disabled,
    placeholder,
    maxLength,
    clearOnSend,
    initQuestion,
    allowFileUpload,
    inputFiles,
    refreshToken,
    clearToken
}: Props) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [question, setQuestion] = useState<string>("");
    const [files, setFiles] = useState<ChatAppFile[]>([]);
    const [showCharacter, setShowCharacter] = useState(false);

    useEffect(() => {
        initQuestion && setQuestion(initQuestion);
    }, [initQuestion]);

    useEffect(() => {
        inputFiles && setFiles(inputFiles);
    }, [inputFiles]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question, files);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const uploadFile = (files: ChatAppFile[]) => {
        setFiles(files);
        onUploadFile(files);
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | HTMLTextAreaElement, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            // Reset height to ensure correct calculation
            textarea.style.height = "auto";

            // try to calculate the height of the content
            const maxHeight = textarea?.style.lineHeight ? parseInt(textarea.style.lineHeight) * 5 : 100;
            const realHeight = textarea.scrollHeight ? Math.min(textarea.scrollHeight, maxHeight) : undefined;
            textarea.style.height = realHeight ? `${realHeight}px` : "auto";
            setShowCharacter(textarea.scrollHeight >= maxHeight);
            return realHeight;
        }
    };

    const handleOnChange = () => {
        const height = adjustTextareaHeight();
        onChange && onChange(question, files || [], height);
    };

    const handleQuestionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onQuestionChange(event.target, event?.target?.value);
        handleOnChange();
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [question]);

    useEffect(() => {
        handleOnChange();
    }, [refreshToken]);

    useEffect(() => {
        if (clearOnSend) {
            setQuestion("");
        }
    }, [clearToken]);

    const { instance } = useMsal();
    const disableRequiredAccessControl = requireAccessControl() && !isLoggedIn(instance);
    const sendQuestionDisabled = disabled || !question.trim() || disableRequiredAccessControl;
    const characterCount = `${question.length}/${maxLength}`;
    const acceptMimesTypes = [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ];
    const maxFileSizeMB = 1;

    if (disableRequiredAccessControl) {
        placeholder = "Please login to continue...";
    }

    return (
        <div className={styles.questionInputContainer}>
            <Stack horizontal className={styles.quotationFilesContainers}>
                {files &&
                    files.map((file, index) => (
                        <FileCard
                            key={index}
                            className={styles.fileCard}
                            color={color}
                            fileName={file.data.name}
                            status={file.status}
                            tooltip={file.status === "error" ? file.error : undefined}
                            onRemove={() => {
                                const deletedFiles = files.filter(f => f.data.name === file.data.name);
                                for (const deletedFile of deletedFiles) {
                                    onRemoveFile(deletedFile);
                                }
                            }}
                        />
                    ))}
            </Stack>
            {files && files.length > 0 && <hr className={styles.hr} />}
            <Stack horizontal className={styles.quotationAnswerContainers}>
                <div className={styles.questionInputButtonsContainer}>
                    {allowFileUpload != false && (
                        <FileUploadButton
                            className={styles.questionInputButton}
                            color={color}
                            sendQuestionDisabled={disabled || disableRequiredAccessControl}
                            multiple={true}
                            acceptMimeTypes={acceptMimesTypes}
                            maxFileSizeMB={maxFileSizeMB}
                            onFileSelected={uploadFile}
                        />
                    )}
                </div>
                <div className={styles.textFieldContainer}>
                    <div className={styles.questionInputTextArea}>
                        <textarea
                            ref={textareaRef}
                            rows={1}
                            disabled={disableRequiredAccessControl}
                            placeholder={placeholder}
                            style={{ resize: "none", overflowX: "hidden" }}
                            maxLength={maxLength}
                            value={question}
                            onChange={handleQuestionChange}
                            onKeyDown={onEnterPress}
                        />
                    </div>
                    {maxLength && showCharacter && <div className={styles.characterCount}>{characterCount}</div>}
                </div>
                <div className={styles.questionInputButtonsContainer}>
                    {!isLoading && (
                        <DefaultButton className={styles.questionInputButton} disabled={sendQuestionDisabled} onClick={sendQuestion}>
                            <FontAwesomeIcon icon={faLongArrowAltUp} color={color} />
                        </DefaultButton>
                    )}
                    {isLoading && (
                        <DefaultButton className={styles.questionInputButton} onClick={cancelRequest}>
                            <FontAwesomeIcon icon={faStopCircle} color={color} />
                        </DefaultButton>
                    )}
                </div>
            </Stack>
        </div>
    );
};
