import React, { useRef, FC } from "react";
import { DefaultButton, IIconProps, Tooltip, TooltipHost } from "@fluentui/react";
import styles from "./FileUpload.module.css";
import { ChatAppFile } from "../../api";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface FileUploadButtonProps {
    className?: string;
    color?: string;
    sendQuestionDisabled: boolean;
    multiple: boolean;
    acceptMimeTypes: string[];
    onFileSelected: (files: ChatAppFile[]) => void; // Now expects an array of files
    maxFileSizeMB?: number; // Optional parameter for maximum file size in MB
}

export const FileUploadButton: FC<FileUploadButtonProps> = ({
    className,
    color,
    sendQuestionDisabled,
    multiple,
    acceptMimeTypes,
    onFileSelected,
    maxFileSizeMB
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t, i18n } = useTranslation();

    const uploadFile = () => {
        fileInputRef.current?.click();
    };

    const calcHash = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async function (event) {
                const fileContent = event.target?.result;
                if (fileContent) {
                    try {
                        const buffer = new Uint8Array(fileContent as ArrayBuffer);
                        const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
                        const hashArray = Array.from(new Uint8Array(hashBuffer));
                        const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
                        resolve(hashHex);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    reject(new Error("Could not read file content"));
                }
            };

            reader.onerror = function (event) {
                reject(new Error(`Error reading file: ${event.target?.error?.message}`));
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const loadFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async function (event) {
                const fileContent = event.target?.result;
                if (fileContent) {
                    resolve(fileContent as string);
                } else {
                    reject(new Error("Could not read file content"));
                }
            };

            reader.onerror = function (event) {
                reject(new Error(`Error reading file: ${event.target?.error?.message}`));
            };

            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const filePromises = Array.from(files).map(file => loadFile(file));
            const fileContents = await Promise.all(filePromises);

            const hashPromises = Array.from(files).map(file => calcHash(file));
            const hashes = await Promise.all(hashPromises);

            const customFiles: ChatAppFile[] = Array.from(files).map((file, index) => {
                const isError = maxFileSizeMB && file.size > maxFileSizeMB * 1024 * 1024;
                return {
                    id: "",
                    data: file,
                    binaryData: fileContents[index],
                    hash: hashes[index],
                    status: isError ? "error" : "none",
                    error: isError ? t("questions.files.tooltip", { maxFileSizeMB }) : undefined
                };
            });

            onFileSelected(customFiles);
            fileInputRef.current!.value = "";
        }
    };

    return (
        <div>
            <input ref={fileInputRef} type="file" hidden accept={acceptMimeTypes.join(",")} multiple={multiple} onChange={handleFileChange} />
            <DefaultButton className={`${className} ${styles.questionInputTextArea}`} disabled={sendQuestionDisabled} onClick={uploadFile}>
                <FontAwesomeIcon icon={faPaperclip} color={color} title={maxFileSizeMB ? t("questions.files.tooltip", { maxFileSizeMB }) : ""} />;
            </DefaultButton>
        </div>
    );
};
