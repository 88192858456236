export const generateUuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const checkUuid = (uuid: string) => {
    return uuid.match("^[0-9a-f]{8}-[0-9a-f]{4}-[0-4][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$");
};
