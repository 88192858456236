import { EventTypes, emitEvent } from "../embed";
import { useEffect, useState } from "react";
import { insideIframe } from "./iframe";

const global = initGlobal();

export function useColorScheme(): [boolean, () => void, () => void] {
    const mql = global.window?.matchMedia("(prefers-color-scheme: dark)");
    const [isDarkMode, setIsDarkMode] = useState(isPreferredColorScheme("dark"));

    function toggleColorScheme() {
        setIsDarkMode(!isDarkMode);
        const newValue = isDarkMode ? "light" : "dark";
        global.localStorage?.setItem("preferred-color-scheme", newValue);
    }

    function resetPreference() {
        global.localStorage?.removeItem("preferred-color-scheme");
        setIsDarkMode(isPreferredColorScheme("dark"));
    }

    const eventListener = () => {
        resetPreference();
    };

    useEffect(() => {
        mql?.addEventListener("change", eventListener);
        return () => mql?.removeEventListener("change", eventListener);
    }, []);

    useEffect(() => {
        enableDarkMode(isDarkMode);
    }, [isDarkMode]);

    enableDarkMode(isDarkMode);
    return [isDarkMode, toggleColorScheme, resetPreference];
}

export function initializeDarkMode(forceDarkMode?: boolean) {
    const isDarkMode = forceDarkMode || isPreferredColorScheme("dark");
    enableDarkMode(isDarkMode);
}

function enableDarkMode(isDarkMode: boolean | undefined) {
    const html = document.documentElement;
    if (isDarkMode) {
        html.classList.add("dark");
        html.classList.remove("light");
    } else {
        html.classList.add("light");
        html.classList.remove("dark");
    }
    emitEvent(EventTypes.EVENT_COLOR_MODE, undefined, isDarkMode ? "dark" : "light");
}

interface Global {
    window?: Window;
    localStorage?: Storage;
}

function initGlobal(): Global {
    let newGlobal: Global = { window: undefined, localStorage: undefined };
    if (typeof window !== "undefined" && typeof newGlobal.window === "undefined") {
        newGlobal.window = window ?? undefined;
        newGlobal.localStorage = window.localStorage ?? undefined;
    }
    return newGlobal;
}

function isPreferredColorScheme(value: string) {
    // If we're inside an iframe, we should always use the light theme
    if (insideIframe) return false;

    const res = global.localStorage?.getItem("preferred-color-scheme")
        ? global.localStorage?.getItem("preferred-color-scheme") == value
        : global.window?.matchMedia(`(prefers-color-scheme: ${value})`).matches;
    return res ?? false;
}
