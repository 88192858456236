import { DefaultButton } from "@fluentui/react";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect, FC } from "react";

import styles from "./ScrollToBottomButton.module.css";

interface ScrollToBottomButtonProps {
    className?: string;
    color?: string;
    children: React.ReactNode;
    show?: boolean;

    top?: string | number;
    left?: string | number;
    right?: string | number;
    bottom?: string | number;
}
const threshold = 100;

const ScrollToBottomButton: FC<ScrollToBottomButtonProps> = ({ className, color, children, show, top, left, right, bottom }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleScroll = () => {
        if (contentRef.current) {
            const isAtBottomWithThreshold = contentRef.current.scrollHeight - contentRef.current.scrollTop <= contentRef.current.clientHeight + threshold;
            setShowScrollButton(!isAtBottomWithThreshold);
        }
    };

    const scrollToBottom = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    };

    useEffect(() => {
        const currentRef = contentRef.current;
        if (currentRef) {
            currentRef.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        handleScroll();
    }, [contentRef]);

    const customStyles: React.CSSProperties = {
        top: top,
        left: left,
        right: right,
        bottom: bottom
    };

    return (
        <>
            <div ref={contentRef} className={`${styles.scrollContainer} ${className}`} style={{ overflowY: "auto" }}>
                {children}
            </div>
            {show && showScrollButton && (
                <DefaultButton className={`${className} ${styles.button}`} style={customStyles} onClick={scrollToBottom}>
                    <FontAwesomeIcon icon={faArrowDown} color={color} />
                </DefaultButton>
            )}
        </>
    );
};

export default ScrollToBottomButton;
