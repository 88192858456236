const getAccessTokenByCode = async (provider: AuthProvider): Promise<AccessToken> => {
    const data: any = {
        grant_type: "authorization_code",
        client_id: provider.clientId,
        redirect_uri: provider.redirectUri,
        code: provider.code
    };

    const result = await _call<AccessToken>({
        url: provider.tokenUrl,
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: Object.keys(data)
            .map(key => `${key}=${data[key]}`)
            .join("&")
    });

    return result;
};

const getAccessTokenByRefreshToken = async (provider: AuthProvider): Promise<AccessToken> => {
    const data: any = {
        grant_type: "refresh_token",
        client_id: provider.clientId,
        refresh_token: provider.refreshToken
    };

    const result = await _call<AccessToken>({
        url: provider.tokenUrl,
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: Object.keys(data)
            .map(key => `${key}=${data[key]}`)
            .join("&")
    });

    return result;
};

// Refactor the _call function to use the CallParams interface
const _call = async <T = any>({ url, method, headers, data }: CallParams): Promise<T> => {
    try {
        const init: RequestInit = {
            method,
            headers,
            ...(data ? { body: data } : {})
        };
        const fetchResult = await fetch(url, init);
        const result = await fetchResult.json();
        if (!fetchResult.ok) {
            throw result;
        }

        return result;
    } catch (e) {
        throw e;
    }
};

export { getAccessTokenByCode, getAccessTokenByRefreshToken };

interface CallParams {
    url: string;
    method: "GET" | "POST" | "PUT" | "DELETE";
    headers: HeadersInit;
    data?: any;
}

interface AuthProvider {
    tokenUrl: string;
    clientId: string;
    redirectUri?: string;
    code?: string;
    refreshToken?: string;
}

interface AccessToken {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
}
