const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, Config, ChatAppFile } from "./models";
import { useLogin, appServicesToken, useTenant, type, AuthSetup } from "../authConfig";
import { ExampleModel } from "../components/Example";
import { getTenant } from "../utils/tenant";

export async function askApi(request: ChatAppRequest, idToken: string | undefined): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function configApi(idToken: string | undefined): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET",
        headers: getHeaders(idToken, true)
    });

    return (await response.json()) as Config;
}

export async function fetchAuthSetup(idToken: string | undefined = undefined): Promise<AuthSetup> {
    const response = await fetch("/auth_setup", {
        method: "GET",
        headers: getHeaders(idToken, true)
    });
    if (!response.ok) {
        throw new Error(`auth setup response was not ok: ${response.status}`);
    }
    return await response.json();
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request),
        signal: request.signal
    });
}

export async function getExampleQuestions(number: number, culture: string, idToken: string | undefined): Promise<ExampleModel[]> {
    const response = await fetch(`${BACKEND_URI}/api/recomendations?number=${number}&culture=${culture}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });

    return (await response.json()) as ExampleModel[];
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export function getConversationById(conversation_id: string, idToken: string | undefined): Promise<any> {
    if (conversation_id === undefined || conversation_id === null) return Promise.resolve([]);
    return fetch(`${BACKEND_URI}/api/conversations/${conversation_id}`, {
        method: "GET",
        headers: getHeaders(idToken)
    }).then(resp => {
        if (!resp.ok && resp.status !== 404) {
            throw Error(resp.statusText);
        }
        return resp.json();
    });
}

//method to get all coversation with params, number of coversation
export function getAllConversations(number: number, idToken: string | undefined): Promise<any> {
    return fetch(`${BACKEND_URI}/api/conversations?number=${number}`, {
        method: "GET",
        headers: getHeaders(idToken)
    }).then(resp => {
        if (resp.status > 299 || !resp.ok) {
            throw Error(resp.statusText);
        }
        return resp.json();
    });
}

//delete conversation with conversation id
export function deleteConversationById(conversation_id: string, idToken: string | undefined): Promise<any> {
    if (conversation_id === undefined || conversation_id === null) return Promise.resolve({});
    return fetch(`${BACKEND_URI}/api/conversations/${conversation_id}`, {
        method: "DELETE",
        headers: getHeaders(idToken)
    }).then(resp => {
        if (resp.status > 299 || !resp.ok) {
            throw Error(resp.statusText);
        }
        return resp.json();
    });
}

// upload files with conversation id
export function uploadFiles(conversation_id: string, files: ChatAppFile[], idToken: string | undefined): Promise<any> {
    if (conversation_id === undefined || conversation_id === null) return Promise.resolve({});
    const formData = new FormData();
    files.forEach(file => {
        formData.append("files", convertBase64ToBlob(file.binaryData), file.data.name);
    });
    formData.append("conversation_id", conversation_id);
    return fetch(`${BACKEND_URI}/api/conversations/${conversation_id}/files`, {
        method: "POST",
        headers: getDefaultHeaders(idToken),
        body: formData
    })
        .then(resp => {
            if (resp.status > 299 || !resp.ok) {
                throw Error(resp.statusText);
            }
            return resp.json();
        })
        .catch(err => {
            console.log(err);
            throw err;
        });
}

export function deleteFiles(conversation_id: string, files: string[], idToken: string | undefined): Promise<any> {
    if (conversation_id === undefined || conversation_id === null) return Promise.resolve({});

    return fetch(`${BACKEND_URI}/api/conversations/${conversation_id}/files`, {
        method: "DELETE",
        headers: getHeaders(idToken),
        body: JSON.stringify({ files: files })
    }).then(resp => resp.json());
}

export function getHeaders(idToken: string | undefined, forceTenant?: boolean): Record<string, string> {
    return {
        ...getDefaultHeaders(idToken, forceTenant),
        "Content-Type": "application/json"
    };
}

function getDefaultHeaders(idToken: string | undefined, forceTenant?: boolean): Record<string, string> {
    return {
        ...getAuthorizationHeader(idToken),
        ...getTenantHeader(forceTenant)
    };
}

function getAuthorizationHeader(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {};
    // If using login and not using app services, add the id token of the logged in account as the authorization
    if (idToken && useLogin() && (appServicesToken == null || type() !== "msal")) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

function getTenantHeader(forceTenant?: boolean): Record<string, string> {
    var headers: Record<string, string> = {};
    // If using tenant, add the origin of the request as the tenant
    if (forceTenant || useTenant()) {
        const tenant = getTenant();
        if (tenant) {
            headers["X-Tenant"] = tenant;
        }
    }

    return headers;
}

function convertBase64ToBlob(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(";base64,");

    // Hold the content type
    const imageType = parts[0].split(":")[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
}
