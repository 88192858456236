import { useCallback, useEffect, useRef, useState } from "react";

// create a functon to allow to retry a funtion until it succeeds, retrying every n seconds until limit is reached
export const retry = async <T>(fn: () => Promise<T>, retriesLeft = 5, interval = 1000): Promise<T> => {
    try {
        const val = await fn();
        return val;
    } catch (error) {
        if (retriesLeft) {
            await new Promise(resolve => setTimeout(resolve, interval));
            return retry(fn, retriesLeft - 1, interval);
        } else {
            throw error;
        }
    }
};

export function useDebounceEffect(fn: () => void, delay: number, dependencies: any[]) {
    const callback = useRef(fn);

    useEffect(() => {
        callback.current = fn;
    }, [fn]);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback.current();
        }, delay);

        return () => clearTimeout(handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...dependencies]);
}

export const useThrottleDebounceEffect = <T extends (...args: any[]) => void>(
    action: T,
    throttleDelay: number,
    debounceDelay: number,
    dependencies: React.DependencyList
): void => {
    const lastInvocationTime = useRef<number>(Date.now() - throttleDelay); // Allow immediate invocation
    const debounceTimer = useRef<number | null | any>(null);

    const actionRef = useRef(action);
    actionRef.current = action; // Update the current action reference on each render

    // Effect for initial call and responding to dependency changes
    useEffect(() => {
        // Immediate action call conditionally based on throttleDelay
        const now = Date.now();
        if (now - lastInvocationTime.current >= throttleDelay) {
            action();
            lastInvocationTime.current = now;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencies]); // Intentionally spreading dependencies here might not work as expected with linter

    const throttledDebouncedAction = useCallback(() => {
        const now = Date.now();
        const timeSinceLastInvocation = now - lastInvocationTime.current;

        const invokeAction = () => {
            actionRef.current();
            lastInvocationTime.current = Date.now();
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
                debounceTimer.current = null;
            }
        };

        if (timeSinceLastInvocation >= throttleDelay && !debounceTimer.current) {
            invokeAction();
        } else {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
            debounceTimer.current = setTimeout(invokeAction, debounceDelay);
        }
    }, [throttleDelay, debounceDelay]);

    // Cleanup
    useEffect(() => {
        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current);
            }
        };
    }, []);
};
