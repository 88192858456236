import React from "react";
import { useEffect } from "react";
import TenantImage from "../Tenant/TenantImage/TenantImage";

interface Props {
    className?: string;
    isDarkMode?: boolean;
    tenant?: string;
}

const logoPath = "/assets/img/";
export const AnswerIcon: React.FC<Props> = ({ className, isDarkMode, tenant }) => {
    const imagen = "logo"; //"icon"
    const [path, setPath] = React.useState(logoPath + imagen + "-light");

    useEffect(() => {
        setPath(logoPath + (isDarkMode ? imagen + "-dark" : imagen + "-light"));
    }, [isDarkMode]);

    return (
        <picture className={className}>
            <TenantImage src={path} alt="Logo" height="28px" aria-label="Main page" className={className} tenant={tenant} />
        </picture>
    );
};
