import { SignOut24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./LogoutButton.module.css";
import { getAccessToken, isJwtExpired } from "../../auth/token";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

interface Props {
    className?: string;
}

export const LogoutButton = ({ className }: Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const token = getAccessToken();
    const isLoggedIn = token != null && !isJwtExpired(token);

    const handleLogoutPopup = () => {
        if (isLoggedIn) {
            navigate("/logout");
        }
    };

    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Button id="logout-button" icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={handleLogoutPopup}></Button>
        </div>
    );
};
